<template>
  <div class="container">
    <div class="row-header">
      <h1>
        <template v-if="isEditMode">Edit Template</template>
        <template v-else>Add Template</template>
      </h1>
    </div>

    <BreadCrumbs />
    <ErrorBanner :message="state.errors.errorMessage" />

    <SpinnerBrand v-if="state.processing" centered wrapped />
    <template v-else>
      <TemplateFileErrorsView
        v-if="state.showFileErrorsView"
        :unknown-variables="state.errors.extra.unknown_variables"
        @close="closeFileErrorsDetails"
      />
      <form v-else action="#" class="content" @submit.prevent="onSubmit">
        <div class="content_row">
          <NewSelectInput
            v-model="state.template.locale"
            label="Locale"
            placeholder="Select a locale"
            :options="localesList"
          />
          <NewSelectInput
            v-model="state.template.sellerCode"
            label="Seller"
            placeholder="Select seller"
            :options="sellersOptions"
            :processing="state.processingConfig"
          />
        </div>
        <div class="content_row">
          <BaseInput v-model="state.template.name" class="content_row_item-wide" label="Name" />
        </div>
        <div class="content_row content_row-company">
          <h4>Apply for company</h4>
          <CheckboxInput
            v-model="state.applyTemplateForCompany"
            class="checkbox"
            :modelValue="state.applyTemplateForCompany"
          />
        </div>
        <transition name="slide-down" duration="200">
          <div v-if="state.applyTemplateForCompany" class="content_row animated_element">
            <SearchInput
              v-model="state.template.company"
              label="Select a company"
              model-field="value"
              :disabled="state.processingCompanies"
              :offline-items="companiesList"
              :item-text-field="({ text }) => text"
              :processing="state.processingCompanies"
              input-mode
            />
          </div>
        </transition>
        <div class="content_row">
          <NewSelectInput
            v-model="state.template.documentType"
            placeholder="Select document type"
            label="Document type"
            :options="documentTypesOptions"
          />
        </div>
        <div class="content_row">
          <FileInput
            v-model="state.template.file"
            ref="refFile"
            :file-types="['dotx', 'docx']"
            :error="state.errors.file"
            @details="openFileErrorsDetails"
          />
        </div>
        <div class="content_row">
          <div class="content_buttons">
            <Button button-type="light" auto-width @click="onCancel">Cancel</Button>
            <Button type="submit" :disabled="!isSubmitButtonEnabled" :processing="processingMethods.POST">Save</Button>
          </div>
        </div>
      </form>
    </template>
  </div>
</template>

<script>
import { computed, reactive, ref, watch, onBeforeMount } from "@vue/composition-api";
import snakeCase from "lodash/snakeCase";

import BaseInput from "@/components/inputs/BaseInput.vue";
import BreadCrumbs from "@/components/breadcrumbs/BreadCrumbs.vue";
import Button from "@/components/buttons/Button.vue";
import CheckboxInput from "@/components/inputs/CheckboxInput.vue";
import ErrorBanner from "@/components/banners/ErrorBanner.vue";
import FileInput from "@/components/inputs/FileInput.vue";
import NewSelectInput from "@/components/inputs/NewSelectInput.vue";
import SpinnerBrand from "@/components/loaders/SpinnerBrand.vue";
import TemplateFileErrorsView from "./TemplateFileErrorsView.vue";

import { CONFIG_ACTION_FETCH_CONFIGS } from "@/store/modules/config";
import prepareAxiosErrors from "@/helpers/prepareAxiosErrors";
import router, { ROUTES_NAMES } from "@/router";
import store from "@/store";
import URLS from "@/config/urls";
import useRequest from "@/composables/network/useRequest";
import SearchInput from "@/components/inputs/SearchInput.vue";

const DEFAULT_TEMPLATE_STATE = {
  company: null,
  file: null,
  name: "",
  locale: null,
  sellerCode: null,
};

const MODES = {
  add: Symbol("add"),
  edit: Symbol("edit"),
};

export default {
  components: {
    SearchInput,
    BaseInput,
    BreadCrumbs,
    Button,
    CheckboxInput,
    ErrorBanner,
    FileInput,
    NewSelectInput,
    SpinnerBrand,
    TemplateFileErrorsView,
  },

  setup() {
    const { fetch, processingMethods } = useRequest({ errorsFormat: "flat" });

    const refFile = ref(null);

    const state = reactive({
      mode: MODES.add,
      showFileErrorsView: false,

      processing: true,
      errors: {},
      fileUnknownVariables: [],

      companiesList: [],
      sellersList: [],

      applyTemplateForCompany: false,
      isFileChanged: false,

      template: {
        ...DEFAULT_TEMPLATE_STATE,
      },
    });

    const isEditMode = computed(() => state.mode === MODES.edit);

    const documentTypesOptions = computed(
      () =>
        store.state.config.country?.templates?.documentTypes?.map((v) => ({
          text: v.replaceAll("_", " "),
          value: v,
        })) || []
    );

    const localesList = computed(
      () => store.state.config.portal?.availableLocales?.map((v) => ({ text: v, value: v })) || []
    );

    const sellersOptions = computed(
      () => store.state.config.country?.sellers?.map(({ code, name }) => ({ text: name, value: code })) || []
    );

    const validateFields = computed(() => {
      const validateFields = ["documentType", "locale", "name", "sellerCode"];

      if (!isEditMode.value || state.isFileChanged) {
        validateFields.push("file");
      }

      if (state.applyTemplateForCompany) {
        validateFields.push("company");
      }

      return validateFields;
    });

    const isSubmitButtonEnabled = computed(() => {
      const isAllFieldsFilled = validateFields.value.every((key) => !!state.template[key]);
      const isInputsValid = [refFile].every((el) => !el?.value?.isError);
      return isAllFieldsFilled && isInputsValid;
    });

    const companiesList = computed(() => {
      return state.companiesList.map(({ id, name }) => ({
        value: id,
        text: name,
      }));
    });

    function onCancel() {
      router.push({ name: ROUTES_NAMES.templates.list });
    }

    async function getCompaniesList() {
      const { data, error, errors } = await fetch(URLS.companies.index);

      if (error) {
        state.errors = errors;
      } else {
        state.companiesList = data;
      }
    }

    async function onSubmit() {
      if (processingMethods.POST) {
        return;
      }

      const data = new FormData();
      const method = "POST";

      let url;

      if (isEditMode.value) {
        const { templateId } = router.currentRoute.params;
        url = URLS.templates.single(templateId);
      } else {
        url = URLS.templates.create;
      }

      validateFields.value.forEach((key) => {
        data.append(snakeCase(key), state.template[key]);
      });

      if (isEditMode.value) {
        data.append("_method", "PUT");
      }

      state.processing = true;
      state.errors = {};

      const { error, errors, errorsExtra } = await fetch({
        url,
        data,
        method,
      });

      if (error) {
        state.errors = {
          ...errors,
          extra: errorsExtra,
        };

        if (errorsExtra.unknown_variables?.length > 0) {
          state.errors.file = {
            message: errors.file,
            actions: [
              {
                type: "details",
                text: "Details",
              },
            ],
          };
        }

        state.processing = false;
      } else {
        onCancel();
      }
    }

    async function getTemplate() {
      state.errors = {};

      const { templateId } = router.currentRoute.params;

      try {
        const url = URLS.templates.single(templateId);
        const { data } = await fetch(url);

        state.template.locale = data.locale;
        state.template.name = data.name;
        state.template.documentType = data.document_type;
        state.template.sellerCode = data.seller.code;

        state.template.file = {
          name: data.file_original_name,
        };

        if (data.company) {
          state.applyTemplateForCompany = true;
          state.template.company = data.company.id;
        }
      } catch (xhrError) {
        state.errors = prepareAxiosErrors(xhrError);
      }
    }

    function openFileErrorsDetails() {
      state.showFileErrorsView = true;
    }

    function closeFileErrorsDetails() {
      state.showFileErrorsView = false;
    }

    onBeforeMount(async () => {
      const { templateId } = router.currentRoute.params;
      const isEditPage = router.currentRoute.name === ROUTES_NAMES.templates.edit;
      const configsList = [{ country: [store.state.auth.country] }, "portal"];

      await store.dispatch(CONFIG_ACTION_FETCH_CONFIGS, configsList);
      await getCompaniesList();

      if (isEditPage) {
        if (templateId) {
          state.mode = MODES.edit;
          await getTemplate();
        } else {
          router.push({ name: ROUTES_NAMES.templates.list });
        }
      }

      state.processing = false;
    });

    watch(
      () => state.template.file,
      (newFile) => {
        if (newFile.size) {
          state.isFileChanged = true;
        }
      }
    );

    return {
      processingMethods,

      refFile,

      state,

      companiesList,
      documentTypesOptions,
      isEditMode,
      isSubmitButtonEnabled,
      localesList,
      sellersOptions,

      onCancel,
      onSubmit,
      openFileErrorsDetails,
      closeFileErrorsDetails,
    };
  },
};
</script>

<style lang="scss" scoped>
.content {
  &_row {
    width: 66%;
    display: grid;
    grid-template-columns: 4fr 4fr;
    column-gap: 34px;
    margin-top: 24px;

    &-company {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    &_item {
      &-wide {
        grid-column-start: 1;
        grid-column-end: 3;
      }
    }

    h4 {
      font-weight: 600;
      font-size: 18px;
      line-height: 1.4em;
    }

    .content_buttons {
      margin-top: 12px;
      grid-column-start: 1;
      grid-column-end: 2;
      display: flex;
      justify-content: flex-end;

      button + button {
        margin-left: 16px;
      }
    }
  }
}

.animated_element {
  transition: all 200ms linear;

  &.slide-down-enter-active {
    transform: translateY(-25%);
    opacity: 0;
  }

  &.slide-down-enter-to {
    transform: translateY(0);
    opacity: 1;
  }

  &.slide-down-leave-active {
    transform: translateY(0);
    opacity: 1;
  }

  &.slide-down-leave-to {
    transform: translateY(-25%);
    opacity: 0;
  }
}
</style>
