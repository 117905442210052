<template>
  <div class="container">
    <div class="row-header">
      <h1>Subscriber Audit Details</h1>
      <LocalSearchInput v-if="!state.processing" v-model="state.localSearchValue" />
    </div>
    <ErrorBanner :message="state.error" />
    <SpinnerAllsports v-if="state.processing" wrapper centered />
    <template v-else>
      <div class="info">
        <p><b>Audit id:</b> {{ auditId }}</p>
        <p><b>Audit date:</b> {{ auditDate }}</p>
      </div>
      <SimpleTable :headers="TABLE_HEADERS" :items="sortedItems" :cells="TABLE_CELLS" />
    </template>
  </div>
</template>

<script>
import { computed, onBeforeMount, reactive, watchEffect } from "@vue/composition-api";

import ErrorBanner from "@/components/banners/ErrorBanner.vue";
import LocalSearchInput from "@/components/inputs/LocalSearchInput.vue";
import SpinnerAllsports from "@/components/loaders/SpinnerAllsports.vue";
import SimpleTable from "@/components/tables/simple/SimpleTable.vue";

import URLS from "@/config/urls";
import router from "@/router";
import store from "@/store";
import { prepareAxiosErrors, request } from "@/helpers/index";

const TABLE_HEADERS = ["Field", "Before", "After"];
const TABLE_CELLS = ["id", "old", "new"];

export default {
  components: {
    ErrorBanner,
    LocalSearchInput,
    SpinnerAllsports,
    SimpleTable,
  },

  setup() {
    const state = reactive({
      processing: false,
      error: null,

      data: null,
      localSearchValue: "",

      tableItems: [],
    });

    const auditId = computed(() => router.currentRoute.params.auditId);
    const auditDate = computed(() => {
      const createdAt = state.data?.metadata?.audit_created_at;
      const locale = store.state.auth.locale;

      let auditDate = null;

      if (createdAt) {
        const dateConfig = {
          weekday: "long",
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        };
        auditDate = new Date(createdAt).toLocaleDateString(locale, dateConfig);
      }

      return auditDate;
    });
    const sortedItems = computed(() => {
      const searchableFields = TABLE_CELLS;
      const filteredList = state.tableItems.filter((item) => {
        const searchStringList = searchableFields.map((field) => item[field]);
        const searchString = searchStringList.join(" ");
        return searchString.includes(state.localSearchValue);
      });

      return filteredList;
    });

    async function getData() {
      state.processing = true;
      state.error = null;
      state.data = null;

      const url = URLS.subscribers.auditDetails(auditId.value);

      try {
        state.data = await request(url);
      } catch (error) {
        const { errorMessage } = prepareAxiosErrors(error);
        state.error = errorMessage;
      }

      state.processing = false;
    }

    function prepareTable(data) {
      if (data && data.modified) {
        const items = Object.entries(data.modified).map(([key, value]) => ({
          id: key,
          new: value.new ?? "–",
          old: value.old ?? "–",
        }));

        items.sort(({ id: id1 }, { id: id2 }) => id1.localeCompare(id2));

        state.tableItems = items;
      } else {
        state.tableItems = [];
      }
    }

    watchEffect(() => {
      prepareTable(state.data);
    });

    onBeforeMount(() => {
      getData();
    });

    return {
      TABLE_HEADERS,
      TABLE_CELLS,

      state,

      auditId,
      auditDate,
      sortedItems,
    };
  },
};
</script>

<style lang="scss" scoped>
.info {
  margin-bottom: 24px;

  p {
    font-size: 15px;
    font-weight: 400;
    line-height: 1.5em;
  }
}
</style>
